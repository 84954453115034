import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import { Swipe, SwipeItem, Popup } from 'vant'



Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')


Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Popup)
router.afterEach(() => {
  window.scrollTo(0, 0);
});