import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode:'history',
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: '/mobile'
    },
    {
      path: '/mobile',
      name: 'mobile',
      component: () => import('../view/mobile/index.vue')
    },
    {
      path: '/pc',
      name: 'pc',
      component: () => import('../view/pc/index.vue')
    },
    {
      path: '/mobile_jyb',
      name: 'mobile_jyb',
      component: () => import('../view/mobile_jyb/index.vue')
    },
    {
      path: '/pc_jyb',
      name: 'pc_jyb',
      component: () => import('../view/pc_jyb/index.vue')
    },
    {
      path: '/mzt',
      name: 'mzt',
      component: () => import('../view/mzt/index.vue')
    },
    {
      path: '/yjt',
      name: 'yjt',
      component: () => import('../view/mzt/yjt.vue'),
      beforeEnter: (to, from, next) => {
        document.title = '上海医健通健康科技有限公司'
        next()
      }
    }
  ],
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
})